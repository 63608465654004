import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  makeStyles,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  TextField,
  Grid,
  Slide,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { format, parseISO } from 'date-fns'

import Logo from 'assets/logo.png'
import Bg from 'assets/bg.png'

import { formatCepMask } from 'components/address/types'
import { GetResponse, ApiResponse } from 'types'
import Api from 'service'

const Component: React.FC = () => {
  const [messages, setMessages] = useState<Array<string>>([])
  const [data, setData] = useState<GetResponse | null>(null)
  const { trackingCode } = useParams<{ trackingCode: string }>()
  const { promiseInProgress } = usePromiseTracker()
  const classes = useStyles()

  useEffect(() => {
    if (trackingCode) {
      setData(null)
      setMessages([])
      trackPromise(
        Api.get({ trackingCode: trackingCode ?? '' }).then(
          (res: ApiResponse) => {
            if (res.data.success && res.data.data) setData(res.data.data)
            else {
              if (res.data.notifications) {
                setMessages(
                  res.data.notifications.map((x: any) => {
                    return x.message
                  }),
                )
              } else {
                setMessages(['Pedido não localizado'])
              }
              setData(null)
            }
          },
          res => {
            if (res?.toString().indexOf('Network Error') > -1)
              setMessages(['Não foi possível realizar a conexão, verifique sua internet e tente novamente'])
            else setMessages(['Um erro ocorreu ao realizar a pesquisa'])
          },
        ),
      )
    }

    return () => {}
  }, [trackingCode])

  return (
    <div className={classes.root}>
      <div className={classes.layout}>
        <Slide in={true} direction='up'>
          <img src={Logo} alt='logo' style={{ marginBottom: 10 }} className={classes.logo} />
        </Slide>

        {data && data.volumes && data.volumes.length > 0 && (
          <>
            <Slide in={true} direction='up'>
              <Paper className={classes.paper}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={3} md={3} lg={3} className={classes.item}>
                    <TextField label='Código de Rastreio' variant='outlined' disabled={true} size='small' value={data.trackingCode} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={3} className={classes.item}>
                    <TextField label='Nota Fiscal' variant='outlined' disabled={true} size='small' value={data.fiscalNoteNumber ?? ''} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={3} className={classes.item}>
                    <TextField label='Pedido' variant='outlined' disabled={true} size='small' value={data.orderNumber} fullWidth />
                  </Grid>
                  {data.deliveryForecast && (
                    <Grid item xs={12} sm={3} md={3} lg={3} className={classes.item}>
                      <TextField
                        label='Previsão de Entrega'
                        variant='outlined'
                        disabled={true}
                        size='small'
                        value={data.deliveryForecast ? format(parseISO(data.deliveryForecast.toString()), "dd'/'MM'/'yyyy") : ''}
                        fullWidth
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} md={12} lg={12} className={classes.item}>
                    <TextField label='Remetente' variant='outlined' disabled={true} size='small' value={data.clientName} fullWidth />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12} className={classes.item}>
                    <TextField label='Destinatário' variant='outlined' disabled={true} size='small' value={data.name ?? ''} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} className={classes.item}>
                    <TextField
                      label='Endereço'
                      variant='outlined'
                      disabled={true}
                      size='small'
                      value={data.street ?? ''}
                      fullWidth
                      multiline
                      minRows={2}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={3} md={3} lg={2} className={classes.item}>
                    <TextField label='Número' variant='outlined' disabled={true} size='small' value={data.number ?? ''} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={9} md={9} lg={10} className={classes.item}>
                    <TextField label='Complemento' variant='outlined' disabled={true} size='small' value={data.complement ?? ''} fullWidth />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4} md={4} lg={4} className={classes.item}>
                    <TextField label='Bairro' variant='outlined' disabled={true} size='small' value={data.neighborhood ?? ''} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} className={classes.item}>
                    <TextField label='Cidade' variant='outlined' disabled={true} size='small' value={data.city ?? ''} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={2} md={1} lg={1} className={classes.item}>
                    <TextField label='Estado' variant='outlined' disabled={true} size='small' value={data.state ?? ''} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={2} md={3} lg={3} className={classes.item}>
                    <TextField
                      label='Cep'
                      variant='outlined'
                      disabled={true}
                      size='small'
                      value={formatCepMask(data.zip?.toString() ?? '')}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Slide>
          </>
        )}

        {promiseInProgress && (
          <Slide in={true} direction='up'>
            <Alert variant='filled' severity='info'>
              Localizando informações, aguarde...
            </Alert>
          </Slide>
        )}

        {messages.length > 0 &&
          messages.map((val, index) => (
            <Slide key={index} in={true} direction='up'>
              <Alert variant='filled' severity='warning' className={classes.message}>
                {val}
              </Alert>
            </Slide>
          ))}

        {data && data.volumes && (
          <Slide in={true} direction='up'>
            <Paper className={classes.paper} elevation={10}>
              {data.volumes.map((volume, index) => (
                <Accordion defaultExpanded={index == 0} key={index}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel-${index}-content`} id={`panel-${index}`}>
                    <Typography>Volume {index + 1}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      {volume.receivedBy?.length > 0 && (
                        <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                          <Alert variant='filled' severity='success' className={classes.alert}>
                            <AlertTitle>Encomenda Entregue</AlertTitle>
                            Recebido por: <strong>{volume.receivedBy}</strong>
                          </Alert>
                        </Grid>
                      )}
                      <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                        <TableContainer component={Paper} className={classes.table}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell align='center'>
                                  <Typography color='primary' className={classes.tableHeader}>
                                    Data/Hora
                                  </Typography>
                                </TableCell>
                                <TableCell align='center'>
                                  <Typography color='primary' className={classes.tableHeader}>
                                    Descrição
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {volume.events &&
                                volume.events
                                  .sort((a, b) => (a.date > b.date ? -1 : 1))
                                  .map((val, index) => (
                                    <TableRow key={index}>
                                      <TableCell align='center' style={{ width: '50%' }}>
                                        {format(parseISO(val.date.toString()), "dd'/'MM'/'yyyy' 'HH:mm")}
                                      </TableCell>
                                      <TableCell align='center' style={{ width: '50%' }}>
                                        {val.description}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Paper>
          </Slide>
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  layout: {
    width: '90%',
    height: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('xl')]: {
      width: '80%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    padding: theme.spacing(1),
  },
  logo: {
    marginTop: theme.spacing(2),
  },
  message: {
    marginBottom: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
  },
  item: {
    marginBottom: theme.spacing(1),
  },
  alert: {
    width: '100%',
  },
  table: {
    width: '100%',
  },
  tableHeader: {
    fontWeight: 500,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundImage: `url(${Bg})`,
    backgroundPosition: 'right top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
}))

export default Component
